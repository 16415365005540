<template>
    <div class="map-wrap-wap"> 
        <!-- wap布局【google地图】 -->
        <div class="wap-banner">
            <b-container class="wap-banner-container">
                <b-row>
                    <div class="nav-group">
                        <div class="nav-group-box">
                            <span class="stopicon"></span>|<span class="txtspe" :class="languageName=='cn'?'cnstyles':''">{{$t('message.nav-head')}}</span></div>
                        </div>
                    <div class="nav-group-txt" :class="languageName=='cn'?'cnstyle':''">{{$t('message.nav')}}</div>
                </b-row>
            </b-container>
        </div>
        <div class="wap-shop-map">
            <div class="pc-fixed-langage">
                <div class="pc-langage-title">{{$t('message.shopfixed')}}</div>
                <div class="pc-langage-txt" @click="languageFlag=!languageFlag">{{$t('message.langage')}}：
                    <span v-show="languageName=='cn'">{{languageCodeObj&&languageCodeObj.cn_name}}</span>
                    <span v-show="languageName=='en'">{{languageCodeObj&&languageCodeObj.en_name}}</span>
                    <span v-show="languageName=='it'">{{languageCodeObj&&languageCodeObj.it_name}}</span>
                    <span class="el-icon-arrow-down"></span>
                </div>
            </div>
            <GMaps id="allmap" class="vue-map-container" :map-data="mapData" :center="center" :paramsMap="paramsMap" :shopList="shopMapList"></GMaps>
        </div>
        <div class="wap-shop-list">
            <div class="dorlist">
                <div class="dorlist-item"  v-for="(item,index) in shopMapList" :key="index">
                    <div class="dor-air"><span class="icon_air"></span></div>
                    <div class="dor-txtbox">
                        <div class="dor_txtbox_title" v-show="languageName=='cn'">{{item.cn_name}}</div>
                        <div class="dor_txtbox_title" v-show="languageName=='en'">{{item.en_name}}</div>
                        <div class="dor_txtbox_title" v-show="languageName=='it'">{{item.it_name}}</div>
                        <div class="dor_txtbox_txt">{{item.address}}</div>
                        <div class="dor_txtbox_txt">{{item.telno}}</div>
                        <div class="dor_txtbox_txt">{{item.business_day_start}}至{{item.business_day_end}} {{item.business_time_start}}-{{item.business_time_end}}</div>
                    </div>
                    <div class="dor-map" @click="getFixedMap(item)"><span class="icon_map"></span></div>
                </div>
            </div>
        </div>
        <!-- 公用布局弹窗【切换语言弹窗】 -->
        <b-modal size="sm" centered v-model="languageFlag" hide-footer :title="$t('message.chioselang')">
            <div class="language-item" v-for="(item,index) in languageList" :key="index" @click="getLanguage(item)">
                <span class="nationalFlag"><img :src="base_url+item.national_flag" alt=""></span>
                {{languageName=='cn'?item.cn_name:''}}
                {{languageName=='en'?item.en_name:''}}
                {{languageName=='it'?item.it_name:''}}
                <span v-show="languageCodeObj&&languageCodeObj.id==item.id" class="iconCheckFlag"><span class="el-icon-check"></span>
                </span>
            </div>
        </b-modal>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import GMaps from '@/components/map/Gmapswap'
import {yooz_url,yooz_lang,setLocalItem} from '@/untils/common';
export default {
    name: 'mapgooglewap',
    inject:['reload'],
    components: {
        GMaps
    },
    data() {
        return{
            base_url:yooz_url,
            languageFlag:false,
            languageName:'',
            languageCodeObj:{},
            languageList:[],
            mapData:{},
            center:{},
            paramsMap:{},
            shopMapList:[],
            features : [
                {
                    lat:-33.91721,
                    lng:151.2263
                },
                {
                    lat:-33.91539,
                    lng: 151.2282
                },
                {
                    lat:-33.91747,
                    lng:151.22912
                   
                },
                {
                    lat:-33.9191, 
                    lng:151.22907
                },
                {
                    lat:-33.91725, 
                    lng:151.23011
                },
                {
                    lat:-33.91872, 
                    lng:151.23089
                },
                {
                    lat:-33.91784, 
                    lng:151.23094
                },
                {
                    lat:-33.91682, 
                    lng:151.23149
                },
                {
                    lat:-33.9179,
                    lng: 151.23463
                },
                {
                    lat:-33.91666, 
                    lng: 151.23468
                },
                {
                    lat:-33.916988, 
                    lng:151.23364
                },
                {
                    lat:-33.91662347903106, 
                    lng:151.22879464019775
                },
            ]
        }
    },
    methods:{
        getLanguage(item){
            if(item){
                this.languageName = item.key;
                this.languageCodeObj = item;
                this.$i18n.locale = item.key;
                localStorage.lang = item.key;
                localStorage.langcode = item.lang;
                this.languageFlag = !this.languageFlag;
                this.reload();
            }
        },
        getFixedMap(item){
            if(item){
                let that = this;
                that.center = item;
                let params = {};
                var geocoder = new google.maps.Geocoder();//创建geocoder服务
                geocoder.geocode( { 'address': item.address}, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        params = {
                            location:results[0].geometry.location,
                            item:item
                        }
                        let origin = { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() }
                        that.paramsMap = params;
                        location.href = 'https://www.google.com/maps/dir/?api=1&origin='+that.mapData.latitude+'%2C'+that.mapData.longitude+'&destination='+origin.lat+'%2C'+origin.lng
                    } else {
                        console.log('wap-Geocode was not successful for the following reason: ' + status);
                    }
                });
            }
        },   
        geolocate() {
            let that = this;
            // 获取当前位置经纬度坐标
            navigator.geolocation.getCurrentPosition(function (position) {
                that.$http.api_datalist_shoplist().then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                res.data.map((v,k)=>{
                                    v.lat = that.features[k].lat;
                                    v.lng = that.features[k].lng;
                                });
                                that.shopMapList = res.data;
                                that.mapData = position.coords;
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }, function (err) {
                console.log(err);
                ElementUI.Message({
                    message:err.message,
                    type:'warning',
                    center: true,
                    offset:10,
                });
                return false;
            }, {
                enableHighAcuracy: true, //位置是否精确获取
                timeout: 5000,//获取位置允许的最长时间
                maximumAge: 0 //多久更新获取一次位置
            });
        },
    },
    created(){
        let that = this;
        that.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        that.geolocate()
        // 获取多国家数据-语言弹窗展示数据
        that.$http.api_datalist_countrylist().then((res)=>{
            if(res){
                if(res.code==1){
                    if(res.data){
                        that.languageList = res.data;
                        this.$store.commit('setLanguageNameList', res.data);
                        setLocalItem('getLanguageListName',res.data);
                        // 根据缓存监测当前选中的语言项
                        if(localStorage.lang&&localStorage.lang!='undefined'){
                            res.data.map((item)=>{
                                if(item.key == localStorage.lang){
                                    that.languageCodeObj = item;
                                }
                            });
                        }else{
                            res.data.map((v)=>{
                                if(v.key == yooz_lang){
                                    this.languageName = v.key;
                                    this.languageCodeObj = v;
                                }
                            });
                        }
                    }
                }else{
                    ElementUI.Message({
                        message:res.message,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
            }
        });
    }
}
</script>

<style lang='scss' scoped>
/deep/.modal-content{
    min-width: 400px;
}
/deep/.bg-info{
    background: #ffffff !important;  
}
/deep/.navbar-dark .navbar-nav .nav-link{
    color: #2E2E2E !important;  
}
/deep/.navbar-dark .navbar-brand{
    color: #2E2E2E !important;  
}
/deep/.navbar-dark .navbar-toggler{
    color:  #2E2E2E !important;  
    border-color: #2E2E2E !important;  
}
// 模态窗样式调整设计图模样
/deep/.modal-content{
    min-width: 200px;
}
/deep/.modal-content{
    border-radius:20px;
}
/deep/.modal-content{
    padding:0 25px;
}
/deep/.modal-header{
    padding-top:25px;
    padding-bottom:20px;
    padding-left:0px;
    padding-right:0px;
    .close{
        font-size: 28px;
        font-weight: normal;
    }
}
/deep/.modal-body{
    padding-left:0px;
    padding-right:0px;
}
.wrap{
    height: 100%;
    width: 100%;
}
.pc-banner{
    height:10%;
    padding-top:10px;
    background:#2E2E2E;
    .pc-nav-container{
        width: 100%;
        padding:0;
        font-size:12px;
        .row{
            width: 100%;
            display: -webkit-flex;
            -webkit-align-items: center; 
            display: flex;
            align-items: center;
            padding:10px 0;
            margin: 0;
            img{
                height:110px;
                margin: 0 auto;
            }
            .nav-group{
                width: 100%;
                display: -webkit-flex;
                -webkit-align-items: center; 
                display: flex;
                align-items: center;
                .nav-group-box{
                    display: inline-block;
                    margin: 0 auto;
                    padding:5px 20px;
                    text-align: center;
                    border:1px solid #ffffff;
                    
                    color: #ffffff;
                    .stopicon{
                        display: inline-block;
                        
                        height: 24px;
                        width:24px;
                        background: url(~@/assets/images/stop_img.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-right: 15px;
                        vertical-align: middle;
                        color: #ffffff;
                    }
                    .txtspe{
                        display: inline-block;
                        font-size:12px;
                        margin-left:15px;
                        color : #ffffff;
                    }
                    .cnstyles{
                        letter-spacing:8px;
                    }
                }
            }
            .nav-group-txt{
                width: 100%;
                font-size:12px;
                color: #ffffff;
                text-align: center;
                padding:10px 0 0;
            }
            .cnstyle{
                letter-spacing:30px;
                padding-left:20px;
            }
        }
    }
}
.pc-content{
    height:90%;
    display: flex;
    -webkit-box-orient: horizontal;
    .pc-left_cen{
        width: 20%;
        overflow-y: auto;
        background: #fff;
        .dortitle{
            font-size:16px;
            padding:20px 0;
            text-align: center;
        }
        .dorlist{
            .dorlist-item{
                display: flex;
                -webkit-box-orient: horizontal;
                padding:0 10px 10px;
                .dor-air{
                    width:10%;
                    padding-top:5px;
                    text-align: center;
                    .icon_air{
                        display: inline-block;
                        width: 20px;
                        height:20px;
                        background: url(~@/assets/images/icon_dw_air.png) no-repeat left top;
                        background-size:100% 100%;
                    }
                }
                .dor-txtbox{
                    width:80%;
                    .dor_txtbox_title{
                        font-size:20px;
                        padding:0 0 10px 0;
                    }
                    .dor_txtbox_txt{
                        color:#9a9a9a;
                        padding:2px 0;
                    }
                }
                .dor-map{
                    width:10%;
                    padding-top:10px;
                    text-align: center;
                    cursor: pointer;
                    .icon_map{
                        display: inline-block;
                        width: 30px;
                        height:30px;
                        background: url(~@/assets/images/icon_dw.png) no-repeat left top;
                        background-size:100% 100%;
                    }
                }
            }
        }
    }
    .pc-right_cen{
        width: 80%;
        position: relative;
        .pc-fixed-langage{
            display: flex;
            -webkit-box-orient: horizontal;
            position: absolute;
            right:10px;
            top:10px;
            z-index: 10;
            height:50px;
            line-height:50px;
            background: #222;
            width:400px;
            border-radius:4px;
            padding:0 10px;
            color: #fff;
            .pc-langage-title{
                color: #fff;
                font-size:16px;
                width:50%;
                padding:0 10px;
            }
            .pc-langage-txt{
                width:50%;
                color: #fff;
                text-align: right;
                cursor: pointer;
                span{
                    color: #fff;
                }
                .el-icon-arrow-down{
                    color: #fff;
                    margin-left: 5px;
                }
            }
        }
    }
}
.wap-banner{
    // height:12%;
    padding:10px 0;
    width:100%;
    background:#2E2E2E;
    .wap-banner-container{
        width: 100%;
        padding:0;
        font-size:12px;
        .row{
            width: 100%;
            display: -webkit-flex;
            -webkit-align-items: center; 
            display: flex;
            align-items: center;
            padding:0 0;
            margin: 0;
            img{
                height:100px;
                margin: 0 auto;
            }
            .nav-group{
                width: 100%;
                display: -webkit-flex;
                -webkit-align-items: center; 
                display: flex;
                align-items: center;
                padding:0 5px;
                .nav-group-box{
                    display: inline-block;
                    margin: 0 auto;
                    padding:5px ;
                    text-align: center;
                    border:1px solid #ffffff;
                    
                    color: #ffffff;
                    .stopicon{
                        display: inline-block;
                        
                        height: 20px;
                        width:20px;
                        background: url(~@/assets/images/stop_img.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-right: 5px;
                        vertical-align: middle;
                        color: #ffffff;
                    }
                    .txtspe{
                        display: inline-block;
                        margin-left:5px;
                        font-size:12px;
                        color : #ffffff;
                    }
                    .cnstyles{
                        letter-spacing:8px;
                    }
                }
            }
            .nav-group-txt{
                width: 100%;
                font-size:12px;
                color: #ffffff;
                text-align: center;
                padding:10px 0 0;
            }
            .cnstyle{
                letter-spacing:30px;
                padding-left:20px;
            }
        }
    }
}
.wap-shop-map{
    height:48%;
    position: relative;
    .pc-fixed-langage{
        display: flex;
        -webkit-box-orient: horizontal;
        position: absolute;
        right:auto;
        top:10px;
        z-index: 100;
        // height:40px;
        // line-height:40px;
        background: #222;
        width:90%;
        margin:0 5%;
        border-radius:4px;
        padding: 10px;
        color: #fff;
        .pc-langage-title{
            color: #fff;
            
            width:50%;
            padding:0 10px;
        }
        .pc-langage-txt{
            width:50%;
            color: #fff;
            text-align: right;
            cursor: pointer;
            
            span{
                color: #fff;
            }
            .el-icon-arrow-down{
                color: #fff;
                margin-left: 5px;
            }
        }
    }
}
.wap-shop-list{
    height:40%;
    overflow-y: auto;
    background: #fff;
    .dorlist{
        .dorlist-item{
            display: flex;
            -webkit-box-orient: horizontal;
            padding:0 10px 10px;
            .dor-air{
                width:10%;
                text-align: center;
                padding-top:5px;
                .icon_air{
                    display: inline-block;
                    width: 20px;
                    height:20px;
                    background: url(~@/assets/images/icon_dw_air.png) no-repeat left top;
                    background-size:100% 100%;
                }
            }
            .dor-txtbox{
                width:80%;
                .dor_txtbox_title{
                    font-size:20px;
                    padding:0 0 10px 0;
                }
                .dor_txtbox_txt{
                    color:#9a9a9a;
                    padding:2px 0;
                }
            }
            .dor-map{
                width:10%;
                padding-top:10px;
                text-align: center;
                .icon_map{
                    display: inline-block;
                    width: 30px;
                    height:30px;
                    background: url(~@/assets/images/icon_dw.png) no-repeat left top;
                    background-size:100% 100%;
                }
            }
        }
    }
}

.language-item{
    padding:10px 0;
    
    position: relative;
    cursor: pointer;
    .nationalFlag{
        display: inline-block;
        width:30px;
        height:20px;
        vertical-align: middle;
        margin-top: -4px;
        margin-right: 8px;
        img{
            width:30px;
            height:20px;
        }
    }
    .italy{
        background:url(~@/assets/images/icon_italy.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .uk{
        background:url(~@/assets/images/icon_uk.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .china{
        background:url(~@/assets/images/icon_china.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .iconCheckFlag{
        position: absolute;
        right:0;
        top:10px;
        display: inline-block;
        color: #1f73b7;
        text-align: right;
        .el-icon-check{
            font-size:20px;
            font-weight: bold;
            color: #1f73b7;
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-shop-map{display: none;}
        .wap-shop-list{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-banner{display: none;}
        .wap-shop-map{display: none;}
        .wap-shop-list{display: none;}
       
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .pc-content{
            .pc-left_cen{
                width: 30%;
            }
            .pc-right_cen{
                width: 70%;
            }
        }
        .wap-banner{display: none;}
        .wap-shop-map{display: none;}
        .wap-shop-list{display: none;}
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-banner{display: none;}
        .pc-content{display: none;}
        .map-wrap{display: none;}
        .map-wrap-wap{
            height: 100%;
            width: 100%;
            .vue-map-container{
                height: 100%;
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-banner{display: none;}
        .pc-content{display: none;}
        .map-wrap{display: none;}
        .map-wrap-wap{
            height: 100%;
            width: 100%;
            .vue-map-container{
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>